import { render, staticRenderFns } from "./AlertBar.vue?vue&type=template&id=256f603e&scoped=true"
import script from "./AlertBar.vue?vue&type=script&lang=js"
export * from "./AlertBar.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "256f603e",
  null
  
)

export default component.exports